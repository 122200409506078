$border: #ddd;
$border-hover: rgb(71, 71, 71);
$text: rgba(102, 102, 102, 1);
$text-hover: #000;
.navbar-brand-image {
  height: 2.5rem;
  width: auto;
  filter: none !important;
}
td {
  max-width: 250px;
}
.dropdown-item.active {
  background-color: rgba(79, 90, 104, 0.337) !important;
}
.-mt-2 {
  margin-top: -8px;
}
#react-select-3-placeholder {
  color: #929dab;
}
.select-control-sm {
  min-width: 20%;
  &.is-valid {
    background-image: none !important;
    padding-right: 0 !important;
  }
  &.is-invalid {
    background-image: none !important;
    padding-right: 0 !important;
  }
}
.select-control {
  &.is-valid {
    padding-right: calc(1.4285714286em + 0.875rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%232fb344%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpolyline points=%2720 6 9 17 4 12%27%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3571428572em + 0.21875rem) center;
    background-size: calc(0.7142857143em + 0.4375rem)
      calc(0.7142857143em + 0.4375rem);
  }
  &.is-valid > div {
    border-color: var(--tblr-form-valid-border-color);
  }

  &.is-invalid {
    padding-right: calc(1.4285714286em + 0.875rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23d63939%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cline x1=%2718%27 y1=%276%27 x2=%276%27 y2=%2718%27%3e%3c/line%3e%3cline x1=%276%27 y1=%276%27 x2=%2718%27 y2=%2718%27%3e%3c/line%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3571428572em + 0.21875rem) center;
    background-size: calc(0.7142857143em + 0.4375rem)
      calc(0.7142857143em + 0.4375rem);
  }
  &.is-invalid > div {
    border-color: var(--tblr-form-invalid-border-color);
  }
  & > div {
    overflow: hidden;
    font-family: var(--tblr-font-sans-serif);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    color: inherit;
    background-color: var(--tblr-bg-forms);
    background-clip: padding-box;
    border: var(--tblr-border-width) solid var(--tblr-border-color);
    appearance: none;
    border-radius: var(--tblr-border-radius);
    box-shadow: 0 0 transparent;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
ul {
  margin-left: 30px;
  counter-reset: item;
}
.wtree {
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;
    &:before {
      content: "";
      counter-increment: item;
      position: absolute;
      top: -10px;
      left: -40px;
      border-left: 2px solid $border;
      border-bottom: 2px solid $border;
      width: 33px;
      height: 22px;
    }
    &:after {
      position: absolute;
      content: "";
      top: 10px;
      left: -40px;
      border-left: 2px solid $border;
      border-top: 2px solid $border;
      width: 0;
      height: 100%;
    }
    &:last-child:after {
      display: none;
    }
  }
}

.wtree {
  li {
    span {
      &:hover,
      &:focus {
        color: $text-hover;
        border: 1px solid $border-hover;
        & + ol {
          li {
            span {
              color: $text-hover;
              border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover + ol,
      &:focus + ol {
        li {
          &:after,
          &:before {
            border-color: $border-hover;
          }
        }
      }
    }
  }
}
.pdfViewer {
  align-items: center;
  min-height: 60vh;
  display: flex;
  padding: 0;
}
.pdfIframe {
  height: 70vh;
}
.spinner-border {
  width: 50px;
  height: 50px;
  border-width: 3px;
}
.mw-250 {
  min-width: 250px !important;
}
.spinner {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.row-background {
  background-color: #c0e6f5;
}
.react-datepicker-wrapper {
  width: 100%;
}
.Spreadsheet__table {
  min-width: 100% !important;
}
.treeview-app {
  height: 100%;
}
.treeview-root {
  height: 100%;
}

.treeview-dragging-source {
  opacity: 0.3;
}
.account-node-item {
  display: block;
  border: 2px solid $border;
  color: $text;
  text-decoration: none;
}
.border-left-none {
  border-left: 0 !important;
}
.treeview-drop-target {
  background-color: #e8f0fe;
}
.custom-drag-preview-root {
  background-color: #1967d2;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
    0 0 0 1px rgba(0, 0, 0, 0.08);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  padding: 4px 8px;
  pointer-events: none;
}
input:focus {
  border: 0 !important;
  border-width: 0 !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;
}
.reactgrid-content .rg-pane.rg-pane-top {
  top: 99px;
}
.reactgrid-content .rg-pane-shadow.shadow-top {
  top: 99px;
}
.reactgrid {
  padding-right: 0 !important;
}
